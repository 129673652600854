<template>
  <environmental-allergies-template
    :environmental-allergies="environmentalAllergies"
    :other-environmental-allergies="otherEnvironmentalAllergies"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import EnvironmentalAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/environmental-allergies/EnvironmentalAllergiesTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { includes } from '@/modules/questionnaire/api/helpers';

import { OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

const { requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [
  requiredArrayField('environmentalAllergies'),
  requiredField('otherEnvironmentalAllergies')
];

export default {
  name: 'EnvironmentalAllergies',
  components: { EnvironmentalAllergiesTemplate },
  mixins: [makeStep(FIELDS)],
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'otherEnvironmentalAllergies') {
        return includes(this.environmentalAllergies, OTHER_VALUE_BINDINGS.value);
      }

      return true;
    }
  }
};
</script>
